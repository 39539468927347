import { getDisclaimerComponent } from '../../../app/shared/vettedSharedUtils';

/**
 * Hydrates the Disclaimer component after being rendered from the server.
 */
const hydrateDisclaimerComponent = () => {
	const vettedDisclaimerContainer = document.getElementById('vetted-disclaimer-component');
	const advisorDisclaimerContainer = document.getElementById('advisor-disclaimer-component');
	const customDisclaimerContainer = vettedDisclaimerContainer || advisorDisclaimerContainer;
	const { affiliateDisclaimer } = window.forbes['simple-site'];

	if (affiliateDisclaimer && customDisclaimerContainer) {
		getDisclaimerComponent(affiliateDisclaimer).hydrate(customDisclaimerContainer);
	}
};

hydrateDisclaimerComponent();
