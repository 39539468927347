import { lGet, lSet } from '../../shared/storageUtils';
import { didPaywallFire, didInlinePaywallFire } from '../../shared/paywallUtilsHelpers';

/**
 * Checks if the window width is below the medium CSS media query.
 * @returns {boolean} True if the window width is less than the medium CSS media query breakpoint.
 */
export const isBelowMedium = () => window.innerWidth < 768;

/**
 * Checks if Lists package navigation is open.
 * @returns {boolean} True if the window width is less than the medium CSS media query breakpoint and the package navigation is visible.
 */
export const isPackageNavOpen = () => isBelowMedium() && !!document.querySelector('.story-package__block--visible');

/**
 * Calculates the current scroll percentage of the page.
 * @returns {number} The scroll percentage of the page.
 */
export const getScrollPercentage = () => {
	const { scrollHeight, clientHeight } = document.documentElement;
	const pageHeight = scrollHeight - clientHeight;
	const scrollPercentage = (window.scrollY / pageHeight) * 100;

	return scrollPercentage;
};

/**
 * Stores the notification expiration time in local storage.
 */
export const storeNotificationExpire = () => {
	const EXPIRATION_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
	const currentTime = Date.now();
	const expirationTime = currentTime + EXPIRATION_DURATION;
	lSet('notif_expire', expirationTime);
};

/**
 * Checks if the notifications have been dismissed by the user.
 * @returns {boolean} True if the notifications are dismissed, false otherwise.
 */
export const isNotificationsDismissed = () => {
	const expirationTimestamp = lGet('notif_expire');
	if (!expirationTimestamp) return false;

	const currentTime = Date.now();
	const notificationExpired = currentTime <= parseInt(expirationTimestamp, 10);

	return notificationExpired;
};

/**
 * Checks if a given url matches current pathname
 * It removes trailing slash from current pathname before comparison.
 * @param {string} url The URL string to compare against the current pathname.
 * @returns {boolean} Returns true if the current pathname is included in the provided URL, otherwise false.
 */
export const checkIsSameDestinationUrl = (url = '') => {
	const normalize = (str) => str.replace(/\/+$/, ''); // Remove trailing slashes
	const currentPathname = normalize(window.location.pathname);

	return url.includes(currentPathname);
};

/**
 * Determines whether notifications should be shown based on whether
 * notifications haven't been dismissed, the package nav isn't open, and the modal paywall hasn't fired.
 * @returns {boolean} True if notifications should be shown, false otherwise.
 */
export const showNotifications = () => !isPackageNavOpen() && !isNotificationsDismissed() && (!didPaywallFire() || didInlinePaywallFire());

/**
 * Checks if a notification is active based on the current time, createdAt, startTime, and duration.
 *
 * @param {Object} notification The notification content.
 * @returns {boolean} True if the notification is active, false otherwise.
 */
const checkIsNotificationActive = (notification) => {
	const {
		createdAt, startTime, duration, status,
	} = notification;

	if (![duration, createdAt, startTime].every(Boolean)) {
		return false;
	}

	const currentTime = new Date();
	const createdDate = new Date(createdAt);
	const [startHours, startMinutes] = startTime.split(':').map(Number);
	const startDateTime = new Date(createdDate.setHours(startHours, startMinutes, 0, 0));

	const [durationHours, durationMinutes] = duration.split(':').map(Number);
	const durationMilliseconds = ((durationHours * 60 * 60) + (durationMinutes * 60)) * 1000;
	const endDateTime = new Date(startDateTime.getTime() + durationMilliseconds);

	const isActive = status.toLowerCase() === 'active';
	const isWithinDuration = currentTime >= startDateTime && currentTime <= endDateTime;

	return isActive && isWithinDuration;
};

/**
 * Checks if categories match user preferences, based on full or partial match.
 *
 * @param {Object} notification The notification content.
 * @returns {boolean} true if all or some categories match, otherwise `false`.
 */
const doesCategoryMatchPreferences = (notification) => {
	const { fullMatch = '', categories = '' } = notification;
	const fuseData = window.fuse || {};

	// If no categories are provided, default to the first active notification.
	if (!categories) return true;

	const categoryList = categories.split(',').map((category) => category.trim()) || [];
	const userPreferencesList = fuseData.fuse?.split(',').map((fuse) => fuse.trim()) || [];

	if (fullMatch.toLowerCase() === 'true') {
		// Check if all categories exist in user preferences
		return categoryList.every((category) => userPreferencesList.includes(category));
	}

	// Check if some categories exist in user preferences
	return categoryList.some((category) => userPreferencesList.includes(category));
};

/**
 * Waits for 'window.fuse' object within a specified maximum time.
 * @param {number} maxTime The maximum time (in milliseconds) to wait for the `window.fuse`
 * @param {number} elapsedTime The amount of time that has already elapsed
 * @returns {Promise} A promise that resolves when the `window.fuse` object is defined or the maximum time has elapsed.
 */
export const waitForFuseObject = async (maxTime) => new Promise((resolve) => {
	const RETRY_INTERVAL = 1000;

	function checkObject(elapsedTime = 0) {
		if (!window.fuse && elapsedTime <= maxTime) {
			setTimeout(() => checkObject(elapsedTime + RETRY_INTERVAL), RETRY_INTERVAL);
		} else {
			resolve();
		}
	}

	checkObject();
});

/**
 * Parses a list of notifications and returns the first active notification's content.
 *
 * @param {Object[]} list - The list of notifications to parse.
 * @returns {Object|null} The content of the first active notification, or null if no active notifications are found.
 */
export const parseNotificationContent = (list) => {
	if (!Array.isArray(list)) return null;

	const { withCategory, withoutCategory } = list
		.filter(checkIsNotificationActive)
		.filter(doesCategoryMatchPreferences)
		.reduce((acc, item) => {
			acc[item.categories ? 'withCategory' : 'withoutCategory'].push(item);
			return acc;
		}, { withCategory: [], withoutCategory: [] });

	const finalNotification = withCategory[0] || withoutCategory[0];

	if (!finalNotification) {
		return null;
	}

	return {
		cta: finalNotification.notificationCTA,
		message: finalNotification.notificationMessage,
		headline: finalNotification.notificationHeader,
		url: finalNotification.url,
	};
};
