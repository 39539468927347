import { insertAd, insertMedianet } from '../../../shared/adInsertion';
import { getAbParam } from '../../../shared/adblock';

let currentStreamIndex;

/**
 * Initializes the streamIndex value for the current article to be used in rendering below the article Ad
 * @param {Number} streamIndex Current stream index for the article in question.
 */
export const setArticleBottomAdValues = (streamIndex = 0) => {
	currentStreamIndex = streamIndex;
};

/**
 * Inserts the below the fold ads
 */
export const renderBelowTheFoldAds = () => {
	const currentArticle = document.querySelector(`#article-stream-${currentStreamIndex}`);
	const belowTheFoldAdsArray = Array.from(currentArticle.querySelectorAll('.medianet, .fbs-ad--artbottom-wrapper'));
	belowTheFoldAdsArray.forEach((unit) => {
		if (!(unit.children || []).length) {
			if (unit.classList.contains('medianet')) {
				insertMedianet({
					position: unit.getAttribute('data-position'), size: unit.getAttribute('data-size'), crid: unit.getAttribute('data-tagId'), params: unit.getAttribute('data-params'),
				});
				unit.parentElement.classList.add(`adblock-${getAbParam()}`);
			} else {
				insertAd('artbottom', currentArticle, `artbottom-${currentStreamIndex}`);
			}
		}
	});
};
