const { useReactComponent } = require('../../../../app/shared/componentLibraryService');
const { FDASubNavigation } = require('../../react-components/SubNavigation/FDASubNavigation');
const { VettedSubNav } = require('../../react-components/VettedSubNav/VettedSubNav');

/**
 * Gets Sub Nav component
 * @param {Object[]} subnavItemsData list of sub nav items
 * @param {string} pageName current page name
 * @param {string} channelName channel name
 * @returns Server side ready react component
 */

const getSubnav = (subnavItemsData, pageName = 'Article', channelName = 'fda') => {
	switch (channelName) {
		case 'fda':

			return useReactComponent(FDASubNavigation, {
				subnavItemsData,
				pageName,
			});
		case 'vetted':

			return useReactComponent(VettedSubNav, {
				subnavItemsData,
			});
		default:

			return null;
	}
};

module.exports = {
	getSubnav,
};
