import { useEffect, useState } from 'react';

import { showNotifications } from './notificationsUtils';

const useStickyPlayerStatus = () => {
	const [isStickyPlayerActive, setIsStickyPlayerActive] = useState(false);

	useEffect(() => {
		if (!showNotifications()) return undefined;

		const floatingStartHandler = () => {
			setIsStickyPlayerActive(true);
		};

		const floatingEndHandler = () => {
			setIsStickyPlayerActive(false);
		};

		document.addEventListener('fbs-video-floating-start', floatingStartHandler);
		document.addEventListener('fbs-video-floating-end', floatingEndHandler);

		return () => {
			document.removeEventListener('fbs-video-floating-start', floatingStartHandler);
			document.removeEventListener('fbs-video-floating-end', floatingEndHandler);
		};
	}, []);

	return isStickyPlayerActive;
};

export default useStickyPlayerStatus;
