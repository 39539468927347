import { isActiveAdSlot } from '../../shared/adUtilities';

/**
 * Handles displaying the inread ad.
 * @param {Number} streamIndex article index in the stream
 * @param {Element} inread the inread ad
 */
function displayInread(streamIndex, inread) {
	const isForbesFinds = window.forbes['simple-site'].isForbesFinds || false;
	if (isForbesFinds) {
		if (inread) {
			inread.style.display = 'none';
		}
		return;
	}

	const inreadCookie = document.cookie.split(';').find((cookieName) => cookieName.indexOf('inread_last_fire') >= 0);

	if (inreadCookie) {
		const cookieValue = inreadCookie.split('=').pop();

		if (cookieValue > Date.now()) {
			return;
		}
	}

	const fbsVideoPlaying = document.querySelector(`#article-container-${streamIndex} fbs-video[playing]`);
	if (fbsVideoPlaying) {
		fbsVideoPlaying.pause();
	}

	const expirationDate = Date.now() + 300000;
	const inreadParent = inread.parentElement;
	document.cookie = `inread_last_fire=${expirationDate}`;
	inreadParent.classList.remove('inread-parent');
	// We probably don't need this if we're already removing the inread-active class when we destroy ads on switching articles.
	Array.from(document.querySelectorAll('fbs-ad.inread-active[position="inread"]')).forEach((el) => el.classList.remove('inread-active'));
	inread.classList.add('inread-active');
	inread.display();
}

/**
 * Handles inread ads.
 * @param {Number} streamIndex article index in the stream
 */
// eslint-disable-next-line import/prefer-default-export
export function handleInread(streamIndex) {
	const inread = document.querySelector(`#article-container-${streamIndex} fbs-ad[position="inread"]`);
	if (!inread) {
		return;
	}

	const autoplayVideo = document.querySelector(`#article-container-${streamIndex} fbs-video[autoplay]`);

	const displayNthInread = () => {
		inread.removeEventListener('bootstrapped', displayNthInread);
		displayInread(streamIndex, inread);
	};
	if (autoplayVideo) {
		autoplayVideo.addEventListener('ended', () => {
			setTimeout(() => {
				if (!autoplayVideo.playing) {
					if (isActiveAdSlot(`article-${streamIndex}-inread`)) {
						displayInread(streamIndex, inread);
					} else {
						inread.addEventListener('bootstrapped', displayNthInread);
					}
				}
			}, 5000);
		});
	} else if (isActiveAdSlot(`article-${streamIndex}-inread`)) {
		displayInread(streamIndex, inread);
	} else {
		inread.addEventListener('bootstrapped', displayNthInread);
	}
}
