import { streamObservable } from './streamService';
import throttle from '../../shared/throttle';

const pinnedEmbedStates = [];
let currentEmbed = {};

/**
 * Pinned Embed Client Class
 */
class PinnedEmbed {
	constructor(article = null, element = null, type = '') {
		this.article = article;
		this.articleHeight = 0;
		this.element = element;
		this.status = 'inactive';
		this.fixedBottom = 0;
		this.fixedTop = 0;
		this.type = type;
	}

	/**
	 * Sets the fixed positions for the pinned embed instance
	 */
	setFixedPositions() {
		const wrapperContainer = this.article.querySelector('.premium-article-wrapper');
		const articleBodyContainer = this.article.querySelector('.article-body-container');

		if (wrapperContainer && articleBodyContainer) {
			this.articleHeight = articleBodyContainer.offsetHeight;
			this.fixedBottom = this.article.offsetTop + wrapperContainer.offsetTop + this.articleHeight;
			this.fixedTop = this.article.offsetTop + wrapperContainer.offsetTop + articleBodyContainer.offsetTop;
		}
	}

	/**
	 * Handles the in and out of view functionality for a pinned embed instance
	 */
	toggleFixedScroll() {
		const articleBodyContainer = this.article.querySelector('.article-body-container');
		// If the height of the article body has changed due to delayed loading or resizing,
		// reset the trigger points and article height for the instance.
		if (articleBodyContainer && this.articleHeight !== articleBodyContainer.offsetHeight) {
			this.setFixedPositions();
		}

		const passedFixedTop = window.innerHeight + window.scrollY >= this.fixedTop;
		const passedFixedBottom = window.innerHeight + window.scrollY >= this.fixedBottom;
		if (this.element && passedFixedTop && !passedFixedBottom) {
			this.element.classList.add('in-view');
		} else {
			this.element?.classList.remove('in-view');
		}
	}

	/**
	 * Sets all cta data for a pinned embed instance
	 */
	setCta() {
		const cta = this.element.querySelector('.pinned-embed-button');

		if (cta) {
			cta.addEventListener('click', () => {
				if (this.status === 'inactive') {
					this.element?.classList.add('active');
					this.status = 'active';
				} else {
					this.element?.classList.remove('active');
					this.status = 'inactive';
				}
			});

			const svg = cta.querySelector('svg');
			const fillColor = cta.style.color;
			if (svg) {
				svg.style.fill = fillColor || '#fff';
			}
		}
	}

	/**
	 * Handles all types of pinned embed instances (there will be more)
	 * @param {Boolean} newEmbed True if this is the first time an embed has appeared
	 */
	setPinnedEmbedFunctionality(newEmbed = false) {
		switch (this.type) {
			case 'slido':
				if (newEmbed) {
					this.setFixedPositions();
					this.setCta();
				}
				/* eslint-disable-next-line no-use-before-define */
				window.addEventListener('scroll', handleToggleFixedScroll);
				break;
			default:
		}
	}
}

/**
 * Event function for the window on scroll event for the fixed position of a pinned embed instance
 */
/* eslint-disable-next-line no-use-before-define */
const handleToggleFixedScroll = throttle(() => currentEmbed.toggleFixedScroll(), 100);

/**
 * Initializes Pinned Embed functionality for each article in the stream
 * @param {Object} serverData The server data for a specific article
 */
const initPinnedEmbed = (serverData = {}) => {
	window.removeEventListener('scroll', handleToggleFixedScroll);

	if (pinnedEmbedStates[serverData.streamIndex]) {
		currentEmbed = pinnedEmbedStates[serverData.streamIndex];
		currentEmbed.setPinnedEmbedFunctionality(false);
	} else {
		const currentArticle = document.querySelector(`#article-container-${serverData.streamIndex || 0}`);
		const pinnedEmbed = currentArticle.querySelector('.pinned-embed');

		if (pinnedEmbed) {
			currentEmbed = new PinnedEmbed(currentArticle, pinnedEmbed, pinnedEmbed.getAttribute('data-type'));
			currentEmbed.setPinnedEmbedFunctionality(true);
			pinnedEmbedStates.push(currentEmbed);
		} else {
			pinnedEmbedStates.push(new PinnedEmbed());
		}
	}
};

streamObservable.subscribe(initPinnedEmbed);
