const { primeInviewEvent } = require('../../shared/tracking');

const addEvergreenRecircTracking = (evergreenRecircContainer) => {
	const evergreenRecircArticles = evergreenRecircContainer?.querySelectorAll('.recirc-unit-article-title');
	evergreenRecircArticles.forEach((article) => {
		primeInviewEvent(article, `evergreen-recirc-inview-${article.innerText}`);
	});
};

document.addEventListener('DOMContentLoaded', () => {
	const evergreenRecircContainer = document.querySelector('.recirc-container');
	if (evergreenRecircContainer) {
		addEvergreenRecircTracking(evergreenRecircContainer);
	}
});
