/**
 * Add class to handle the top hero ribbon.
 * @param {String} queryClass The class to query.
 * @param {String} addClass The class to add to the element.
 */
const adWrapperClass = (queryClass, addClass) => {
	const element = document.querySelector(queryClass);
	if (element) {
		element.classList.add(addClass);
	}
};

/**
 * Handles hero ad render
 * @param {Object} detail The details from the render event
 * @param {Number} streamIndex The current article's stream index
 */
const renderHeroAd = (detail = {}, streamIndex = 0) => {
	const { size = [] } = detail;

	// takeover ad
	if (size[0] && size[0] === 7) {
		adWrapperClass(`#article-container-${streamIndex} .fbs-ad--top-wrapper`, 'fbs-ad--top-wrapper--takeover');
		adWrapperClass(`#article-container-${streamIndex} .story-package__nav-wrapper`, 'story-package__nav-wrapper--hero');
		adWrapperClass(`#article-container-${streamIndex} .anchor-navbar__wrapper`, 'anchor-navbar__wrapper--hero');
		// we wait 2 second to load the ad and for it cover the page
		// so the shift can happen in the background
		setTimeout(() => {
			adWrapperClass(`#article-container-${streamIndex} .premium-breadCrumbs-container`, 'premium-breadCrumbs-container--hero');
		}, 2000);
	}
};

export default renderHeroAd;
