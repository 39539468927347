const { default: Disclaimer } = require('@forbes/fbs-components/dist/cjs/components/Disclaimer/Disclaimer');
const { useReactComponent } = require('./componentLibraryService');

/**
 * gets disclaimer component
 * @param {string} text disclaimer text
 * @returns {object} disclaimer component
 */

const getDisclaimerComponent = (text) => useReactComponent(Disclaimer, text ? { text } : null);

/**
 * gets affiliate links and award values from embedData
 * @param {object} article article object
 * @returns {object} object with affiliate links and award values
 */
const getEmbedDataVals = (article = {}) => {
	const findsEmbedData = article.embedData?.map((item) => item.data || []);
	const findsLinks = findsEmbedData?.filter((item) => item.findsLink);
	const attributes = findsLinks?.map((item) => item.attributes || []);

	const affiliatedLinks = findsLinks?.map((item) => {
		const links = [];
		links.push(item.findsLink);
		(item.productAlternatives || []).forEach((ele) => links.push(ele.affiliatizedUrl));

		return links;
	});

	const award = attributes?.map((attribute) => attribute.map((ele) => ele.type));

	return {
		affiliatedLinks,
		award,
	};
};

module.exports = {
	getDisclaimerComponent,
	getEmbedDataVals,
};
