import React, { useEffect, useState } from 'react';
import { CardArticle, CardStream, AdContainer } from '@forbes/fbs-components';
import cx from 'classnames';
import PropTypes from 'prop-types';
import FourCardArticle from '../FourCardArticle/FourCardArticle';
import { fireGAEvent } from '../../shared/trackingUtils';
import { fetchArticles } from '../PopularArticles/utils';
import throttle from '../../shared/throttle';

const PROGRESSIVE_LOAD_PERCENTAGE = 1;

/**
 * Fires a google analytics event
 * @param {String} label event label
 */
const triggerGAEvent = (label, value) => {
	fireGAEvent({
		eventCategory: 'Template Area Interaction',
		eventAction: 'click',
		eventLabel: label,
		eventValue: value,
	});
};

/**
 * Creates inline ad component
 * @param {Boolean} isMobile Checks if the device is mobile
 * @param {String} className Additional class name
 * @param {Number} index number of mobilex index
 * @param {Object} customTargeting custom targeting for ad
 * @param addProgressiveAd function to add fbs-ad to shared progressive ads array
 * @returns Inline ad component
 */
const InlineAdX = ({
	isMobile,
	className,
	index,
	customTargeting,
	addProgressiveAd,
}) => (
	<AdContainer
		type={isMobile ? 'inlineAdMobile' : 'inlineAd'}
		className={cx('inline-ad-container', 'xl-recirc-inline-ad', className)}
		showLabel
	>
		{isMobile ? (
			<fbs-ad
				progressive="true"
				position="mobilex"
				id={`article-0-ad-${index}`}
				ad-id={`inline-article-0-ad-${index}`}
				class="article-mobile-ad"
				ref={addProgressiveAd}
				custom-targeting={`${customTargeting},mffxlrecirc:true`}
			/>
		) : (
			<fbs-ad
				progressive="true"
				position="topx"
				custom-targeting="topx:R1,mffxlrecirc:true"
				class="topx-ad"
				ref={addProgressiveAd}
			/>
		)}
	</AdContainer>
);

InlineAdX.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	className: PropTypes.string,
	index: PropTypes.number,
	customTargeting: PropTypes.string.isRequired,
	addProgressiveAd: PropTypes.func.isRequired,
};

const XLRecirc = ({
	showTopx = true, showRecX = true, className, isVetted = false, vettedRecircData = [],
}) => {
	const [data, setData] = useState(null);
	const [showXLRecirc, setShowXLRecirc] = useState(false);
	const [isSmallDevice, setIsSmallDevice] = useState(false);
	const [mobileInlineAds, setMobileInlineAds] = useState(undefined);
	const [progressiveAds, setProgressiveAds] = useState([]);

	const addProgressiveAd = (ref) => {
		if (ref && !progressiveAds.includes(ref)) {
			setProgressiveAds((curAds) => [...curAds, ref]);
		}
	};

	useEffect(() => {
		const checkProgressiveAds = () => {
			progressiveAds.forEach((ad) => {
				const { bottom } = ad.getBoundingClientRect();

				// destroy ads when user scrolls past them into next article
				if (bottom < -window.innerHeight * PROGRESSIVE_LOAD_PERCENTAGE) {
					if (ad.div && (!ad.hasAttribute('destroyed') || ad.getAttribute('destroyed') === 'false')) {
						ad.destroyAd();
						ad.setAttribute('destroyed', 'true');
					}
					return;
				}

				if ((bottom - window.innerHeight < window.innerHeight * PROGRESSIVE_LOAD_PERCENTAGE)
					&& !ad.hasAttribute('display-called')
				) {
					if (ad.hasAttribute('destroyed') && ad.getAttribute('destroyed') === 'true') {
						ad.removeAttribute('destroyed');
						ad.recallAd();
					} else {
						ad.display();
					}
				}
			});
		};
		checkProgressiveAds();
		const scrollHandler = throttle(checkProgressiveAds, 200);
		window.addEventListener('scroll', scrollHandler, { passive: true });
		return () => {
			window.removeEventListener('scroll', scrollHandler);
		};
	}, [progressiveAds]);

	useEffect(() => {
		const getData = async () => {
			try {
				const response = await fetchArticles(true);
				setData(response);
			} catch (e) {
				console.log('Error fetching data 2', e);
			}
		};

		if (isVetted) {
			setData(vettedRecircData);
		} else {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallDevice(window.innerWidth <= 768);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		const mobileXArray = document.querySelectorAll('.article-mobile-ad');
		setMobileInlineAds(mobileXArray.length);
	}, []);

	useEffect(() => {
		const blockComponent = window.forbes['simple-site'].specialSlot || window.forbes['simple-site'].swimLane;
		setShowXLRecirc(!blockComponent);
	}, []);

	if (!showXLRecirc || mobileInlineAds === undefined) { return null; }

	return data && !!data?.length && (
		<>
			{!isVetted && showTopx && <InlineAdX isMobile={isSmallDevice} index={mobileInlineAds + 1} customTargeting="mobilex:R1" addProgressiveAd={addProgressiveAd} />}
			<div
				className={cx('xl-recirc-container', className, { 'vetted-recirc-container': isVetted })}
			>
				<div className="xl-recirc">
					<h1
						className="section-title"
					>
						More From Forbes
					</h1>
					<FourCardArticle data={data.slice(0, 4)} isSmallDevice={isSmallDevice} />
					{!isVetted && isSmallDevice && <InlineAdX isMobile index={mobileInlineAds + 2} customTargeting="mobilex:R2" addProgressiveAd={addProgressiveAd} />}
					<div className="two-sections">
						{data.slice(4, 6).map(({
							index, image, uri, alt, size, title, isPremium, authors, published_date: timestamp,
						}) => (
							<CardArticle
								key={`${title}-${index}`}
								image={image}
								href={uri}
								alt={alt}
								size={size}
								title={title}
								isPremium={isPremium}
								className="two-card-article"
								timestamp={isSmallDevice ? new Date(timestamp).getTime() : null}
								classNameByLines="custom-stream"
								onClick={() => triggerGAEvent(`More From Forbes - XL Recirc - Story ${index + 1}`, index + 1)}
								authors={[
									{
										link: authors[0]?.link,
										name: authors[0]?.name,
										type: authors[0]?.type,
									},
								]}
							/>
						))}
					</div>
					<FourCardArticle isReversed data={data.slice(6, 10)} hideImage />
					{!isVetted && isSmallDevice && <InlineAdX isMobile index={mobileInlineAds + 3} customTargeting="mobilex:R3" addProgressiveAd={addProgressiveAd} />}
					<div className="three-card-stream">
						{data.slice(10, 13).map(({
							index, image, uri, title, published_date: timestamp, authors,
						}) => (
							<CardStream
								className="card-stream-container"
								key={`${title}-${index}`}
								articleUrl={uri}
								title={title}
								timestamp={new Date(timestamp).getTime()}
								target="_self"
								description=""
								imageSrc={isSmallDevice ? null : image}
								onClick={() => triggerGAEvent(`More From Forbes - XL Recirc - Story ${index + 1}`, index + 1)}
								byLineProps={{
									author: [
										{
											link: authors[0]?.link,
											name: authors[0]?.name,
											type: authors[0]?.type,
										},
									],
									displayBy: true,
									size: 'small',
									className: 'custom-stream',
								}}
							/>
						))}
					</div>
				</div>
				{!isVetted && showRecX && !isSmallDevice
				&& (
					<div className="recirc-right-rail">
						<AdContainer type="rightRail" showLabel>
							<fbs-ad position="recx" custom-targeting="recx:R1,mffxlrecirc:true" ref={addProgressiveAd} progressive="true" />
						</AdContainer>
					</div>
				)}
			</div>
		</>
	);
};

XLRecirc.propTypes = {
	showTopx: PropTypes.bool,
	showRecX: PropTypes.bool,
	className: PropTypes.string,
	isVetted: PropTypes.bool,
	vettedRecircData: PropTypes.array,
};

export default XLRecirc;
