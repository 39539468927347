const { useReactComponent } = require('../../../../app/shared/componentLibraryService');
const { AssetPriceTicker } = require('../../react-components/AssetPriceTicker/AssetPriceTicker');

/**
 * Gets Asset Price Ticker component
 * @param {Object[]} topAssets list of top 10 assets
 * @returns Server side ready react component
 */

const getAssetPriceTicker = (topAssets) => useReactComponent(AssetPriceTicker, {
	topAssets,
});

module.exports = {
	getAssetPriceTicker,
};
