import { primeInviewEvent } from '../../shared/tracking';
import { serverData } from '../../shared/clientConfigService';

/**
 * Add tracking for the fbs pricing modules on scroll
 */
const setFbsPricingTracking = () => {
	const findsModuleInfo = document.querySelectorAll('.finds-module-info');
	findsModuleInfo.forEach((module) => {
		const findsEmbedBtns = module.querySelectorAll('.finds-embed');

		findsEmbedBtns.forEach((btn) => {
			let trackingLabel = btn.getAttribute('data-ga-track');
			trackingLabel = trackingLabel.replace('Link:', 'View:');
			primeInviewEvent(btn, trackingLabel, 0.05);
		});
	});
};

/**
 * Add tracking for the fbs carousel on scroll
 */
// const setCouponCarouselTracking = () => {
// 	const vettedCouponCarousel = document.querySelector('.vetted-coupon-carousel');
// 	if (vettedCouponCarousel) {
// 		const scrollEventLabel = 'Vetted Coupons - In-View';
// 		primeInviewEvent(vettedCouponCarousel, scrollEventLabel, 0.05, 'scroll');
// 	}
// };

document.addEventListener('DOMContentLoaded', () => {
	if (serverData.isForbesFinds) {
		setFbsPricingTracking();
		// will be commented out until we have a new vendor
		// setCouponCarouselTracking();
	}
});
