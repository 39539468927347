import React from 'react';
import {
	x, facebook, linkedin, email, reddit, pocket, flipboard, link,
} from '@forbes/fbs-icons';
import { fireGAEvent } from '../../shared/trackingUtils.js';

const makeReactComponent = (icon) => (React.createElement('svg', { dangerouslySetInnerHTML: { __html: icon } }));

const sharingIcons = {
	copy: makeReactComponent(link),
	Email: makeReactComponent(email),
	Facebook: makeReactComponent(facebook),
	Twitter: makeReactComponent(x),
	Linkedin: makeReactComponent(linkedin),
	Pocket: makeReactComponent(pocket),
	Reddit: makeReactComponent(reddit),
	Flipboard: makeReactComponent(flipboard),
};

const prepareOnClickHandler = (platform, sharingLink, uri) => (
	() => {
		if (platform === 'copy-link') {
			navigator.clipboard.writeText(uri);
		} else {
			window.open(sharingLink, 'window', 'width=400,height=500');
		}
		fireGAEvent({
			eventAction: 'click',
			eventLabel: `share-${platform.toLowerCase()}-click-${uri}`,
		});
	}
);

const prepareTrackingProps = (platform, uri) => ({
	category: 'Template Area Interaction',
	action: 'scroll',
	label: `share-${platform.toLowerCase()}-in-view-${uri}`,
});

const shareItems = (socialMap, uri) => Object.values(socialMap).map(({ platform, sharingLink }) => ({
	icon: sharingIcons[platform],
	platform: platform === 'Twitter' ? 'X' : platform,
	onClick: prepareOnClickHandler(platform, sharingLink, uri),
	trackingProps: prepareTrackingProps(platform, uri),
}));

const getSocialShareMenuData = ({ uri, socialMap }) => {
	const socialShareMenuData = {
		articleUrl: uri,
		className: 'share-menu-modal',
		onClick: () => fireGAEvent({
			eventAction: 'click',
			eventLabel: `share-modal-open-${uri}`,
		}),
		inViewTrackingProps: {
			category: 'Template Area Interaction',
			action: 'scroll',
			label: `share-in-view-${uri}`,
		},
		subscribeButtonInViewTrackingProps: prepareTrackingProps('subscribe', uri),
		onSubscribeButtonClick: () => fireGAEvent({
			eventAction: 'click',
			eventLabel: `share-subscribe-click-${uri}`,
		}),
		shareItems: [
			{
				icon: sharingIcons.copy,
				platform: 'Copy link',
				onClick: prepareOnClickHandler('copy-link', '', uri),
				trackingProps: prepareTrackingProps('copy-link', uri),
			},
			...shareItems(socialMap, uri),
		],
		placement: 'left',
	};
	return socialShareMenuData;
};

export default getSocialShareMenuData;
