import { getTracking } from '../../shared/clientConfigService';
import pushEvent from '../../shared/setTrackingUtils';

async function openNavigator(articleTitle, articleUrl) {
	try {
		await navigator.share({
			title: articleTitle,
			text: articleTitle,
			url: articleUrl,
		});
	} catch (error) {
		console.log(`Couldn't execute navigator: ${error}`);
	}
}

const addToClipboard = async (articleTitle, articleUrl) => {
	await navigator.clipboard.writeText(`${articleTitle}\n\n ${articleUrl}`);
};

export default async function addMobileNavigator(article = document) {
	const shareNavigator = article.querySelector('.with-border-share-icon');
	if (shareNavigator) {
		shareNavigator.addEventListener('click', async () => {
			const trackingData = getTracking();
			const articleTitle = trackingData.title;
			const articleUrl = window.location.href;
			if (navigator.share) {
				await openNavigator(articleTitle, articleUrl);
			} else if (navigator.clipboard) {
				await addToClipboard(articleTitle, articleUrl);
			} else {
				console.log('clipboard not identified');
			}
		});

		pushEvent(shareNavigator, 'click', 'Template Area Interaction', 'mobile share');
	}
}
