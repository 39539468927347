const retracted = document.querySelector('.contributor__retracted');
const clickables = document.querySelector('article').querySelectorAll('a,button,fbs-cordial');

function hideItems(item) {
	item.setAttribute('aria-hidden', true);
	item.setAttribute('tabindex', -1);
}

if (retracted) {
	window.addEventListener('DOMContentLoaded', () => {
		clickables.forEach((cleckable) => { hideItems(cleckable); });

		const observer = new MutationObserver(((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.addedNodes.length) {
					mutation.addedNodes.forEach((child) => {
						hideItems(child);
					});
				}
			});
		}));

		const newButtons = document.querySelectorAll('fbs-cordial');
		newButtons.forEach((element) => {
			observer.observe(element, {
				childList: true,
			});
		});
	});
}
