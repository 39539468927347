import { createCookie, getCookie } from '../../../../shared/cookieUtils';

// Expires at Midnight
const isIE = !!window.MSInputMethodContext && !!document.documentMode;
let expiresAtMidnight;
if (isIE) {
	expiresAtMidnight = new Date().toLocaleString('en-US');
} else {
	expiresAtMidnight = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });
}
expiresAtMidnight = new Date(expiresAtMidnight);
expiresAtMidnight.setHours(23);
expiresAtMidnight.setMinutes(59);
expiresAtMidnight.setSeconds(59);

/**
 * Resets cookies to 'A'
 */
const clearCookies = () => {
	document.cookie = 'malcolm=A;path=/;domain=.forbes.com;';
	document.cookie = 'forbesbeta=A;path=/;domain=.forbes.com;';
	if (window.location.href.split('?')[1]) {
		window.location = window.location.href.split('?')[0];
	} else {
		window.location.reload();
	}
};

/**
 * Checks cookies and sets new cookies if a change is needed
 */
const checkCookies = () => {
	const { 'simple-site': { bucket = 'A' } = {} } = window.forbes;
	const betaFlag = document.querySelector('.beta-flag.beta-flag-hidden');
	const reloadType = ((performance || {}).navigation || {}).type || 0;

	const subnav = document.querySelector('.container__subnav--outer');

	// If the user did not go back in history, then we can set the cookie to whatever the bucket is.
	if (reloadType !== 2) {
		document.cookie = createCookie('malcolm', bucket, expiresAtMidnight.toUTCString());
		document.cookie = createCookie('forbesbeta', bucket === 'A' ? 'A' : 'B', expiresAtMidnight.toUTCString());
	}

	// If the bucketCookie is not 'A', we show the beta flag
	const bucketCookie = (getCookie(document.cookie, 'malcolm') || '').split('=')[1] || 'A';
	if (bucketCookie !== 'A' && betaFlag) {
		if (subnav) {
			const universalHeader = document.querySelector('.universal-header');
			betaFlag.style = `top: ${universalHeader.getBoundingClientRect().height + subnav.clientHeight}px;`;
		}

		betaFlag.classList.remove('beta-flag-hidden');
		const betaSpan = betaFlag.querySelector('span');
		betaSpan.addEventListener('click', clearCookies);

		// mobile only is handled in JS desktop is handled in src/assets/sass/shared/_beta-flag.scss
		if (window.outerWidth <= 768) {
			const betaFlagLabel = betaFlag.querySelector('.beta-flag-label');
			const betaFlagInfo = betaFlag.querySelector('.beta-flag-info');

			const handleMobileClick = () => {
				betaFlag.classList.add('beta-flag-full-width');
				betaFlagLabel.style.display = 'none';
				betaFlagInfo.style.display = 'flex';
				betaFlag.removeEventListener('click', handleMobileClick);
			};

			const betaDismissButton = document.querySelector('.beta-close-icon');
			if (betaDismissButton) {
				betaDismissButton.addEventListener('click', (e) => {
					e.stopPropagation();
					betaFlag.classList.remove('beta-flag-full-width');
					betaFlagLabel.style.display = 'flex';
					betaFlagInfo.style.display = 'none';
					betaFlag.addEventListener('click', handleMobileClick);
				});
			}

			betaFlag.addEventListener('click', handleMobileClick);
		}
	}
};

document.addEventListener('DOMContentLoaded', checkCookies);
