/**
 * Updates which article is the current article in the stream
 * @param {Number} articleIndex Current article position in the stream
 */
function updatePrintPage(articleIndex) {
	const currentPages = Array.from(document.querySelectorAll('.current-page'));
	currentPages.forEach((page) => page.classList.remove('current-page'));
	document.querySelector(`#article-container-${articleIndex}`)?.classList.add('current-page');
}

/**
 * Adds click event listener to the print button for the current article if it is not added
 * @param {Number} articleIndex Current article position in the stream
 */
function addPrintEventListener(articleIndex) {
	const printBtn = document.querySelector(`#article-container-${articleIndex} .print`);
	if (!!printBtn && !printBtn.getAttribute('clickable')) {
		printBtn.addEventListener('click', () => {
			updatePrintPage(articleIndex);
			window.print();
		});
		printBtn.setAttribute('clickable', true);
	}
}

module.exports = {
	addPrintEventListener,
	updatePrintPage,
};
