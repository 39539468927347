import { getCookie } from '@forbes/fbs-utils';

/**
 * Checks if the page meter is expiring or already expired
 * @returns True if expiring or expired, false otherwise
 */
const isMeterExpiring = () => {
	const meterCookie = (getCookie(document.cookie, '_pc_meter_expiration') || '').split('=') || [];
	return meterCookie.length && ['expiring', 'expired'].includes(meterCookie[1]);
};

const isZetteUser = () => window.forbes.lastCustomData?.zette_access;

const hasJwtAccess = () => (
	window.Zephr?.utils?.productMaps?.unlimitedSubscriptionProducts.some(
		(productId) => window.forbes.lastCustomData?.jwt_access?.includes(productId),
	)
);

const didInlinePaywallFire = () => /[I-i]nline/.test(window.Zephr?.outcomes?.article?.outcomeLabel);

// Match any combination of reg/pay wall from outcome name. Ads should not render underneath, and article-modal should show
const didPaywallFire = () => /.*(?:\s|^)(reg|pay)[-\s]?wall(?:\s|$).*/i.test(window.Zephr?.outcomes?.article?.outcomeLabel);

export {
	isMeterExpiring,
	isZetteUser,
	hasJwtAccess,
	didInlinePaywallFire,
	didPaywallFire,
};
