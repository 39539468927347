const React = require('react');
const { PropTypes } = require('prop-types');

const { adelaideWhite } = require('@forbes/fbs-icons');

const {
	default: Summary,
} = require('@forbes/fbs-components/dist/cjs/components/Summary/Summary');

const { reactComponentsTracking } = require('../../shared/react-tracking');

const { useEffect } = React;

/**
 * Returns the tracking data
 * @returns {Object} the tracking data
 */
const getTrackingProps = () => ({
	track: reactComponentsTracking,
	category: 'Summary',
	action: 'Click',
});

/**
 * Returns topics data with tracking attached
 * @param {Array} topics topics data
 * @returns {Array} topics data with the tracking data attached
 */
const formatTopicData = (topics) => topics.map((topic) => ({
	...topic,
	trackingProps: {
		...getTrackingProps(),
		label: `ai-article-summary-relatedtopic-{${topic.name}}-click`,
	},
}));

const GenAISummary = ({ summary, bullets, topics }) => {
	const summaryContentPresent = !!bullets?.length || summary;
	
	useEffect(() => {
		summaryContentPresent && reactComponentsTracking('Summary', 'Load', 'ai-article-summary-true');
	}, []);

	return summaryContentPresent && (
		<div className="genAiSummary">
			<Summary
				summary={summary}
				bullets={bullets}
				icon={<span className="summaryIcon" dangerouslySetInnerHTML={{ __html: adelaideWhite }} />}
				label="Summary"
				maxLines={4}
				topics={formatTopicData(topics)}
				topicsLabel="Related Topics"
				trackingProps={{
					...getTrackingProps(),
					expandLabel: 'ai-article-summary-expand-click',
					collapseLabel: 'ai-article-summary-collapse-click',
				}}
			/>
		</div>
	);
};

GenAISummary.propTypes = {
	summary: PropTypes.string,
	bullets: PropTypes.array,
	topics: PropTypes.array,
};

module.exports = {
	GenAISummary,
};
