import getSaveArticle from './getSaveArticle';
import { getCookie, createCookie } from '../../../../shared/cookieUtils';
import { fetchDataForSavedArticlesTemplate } from '../../homepage/for-you';
import { fireGAEvent } from '../../shared/trackingUtils';
import { checkIsBrandVoiceContent } from './articleUtilities';

const { Zephr } = window;
const {
	tracking,
} = window.forbes['simple-site'];
const body = document.querySelector('.main-content--body');
const isSocialSharingContainerExist = document.querySelector('.article-sharing__container') === null;
const isMastheadExcluded = tracking.templateType.includes('premium') && !tracking.templateSubType.includes('masthead');
const isBrandVoice = checkIsBrandVoiceContent();
const isDateWrapperExist = document.querySelector('.content-data.metrics-text');
// To set the expiry date for the cookie a year after creation
const cookieExpiryDate = new Date((+new Date()) + (365 * 24 * 60 * 60) * 1000).toUTCString();

let saveButton;
let uri;

/**
 * To insert the template for the forYou popup.
 */
const generateForYouPopup = async (isSaved) => {
	if (isSaved) {
		return;
	}

	const forYouPopUp = document.querySelector('.for-you-popup');

	if (!getCookie(document.cookie, 'save-first-visit').split(',').includes('loggedIn')) {
		forYouPopUp.innerHTML = await fetchDataForSavedArticlesTemplate(Zephr.user);
		fireGAEvent({ eventAction: 'load', eventLabel: `save-article-for-you-recirc-in-view-${uri}` });

		const closeCard = forYouPopUp.querySelector('.close-card');
		closeCard.addEventListener('click', () => { forYouPopUp.firstElementChild?.remove(); });

		body.addEventListener('click', () => { forYouPopUp.firstElementChild?.remove(); });
		document.cookie = createCookie('save-first-visit', 'visited,loggedIn', cookieExpiryDate);
	}
};

const saveArticleFunctionality = (article = document) => {
	const { templateType, templateSubType } = window.forbes['simple-site'].tracking;
	const isPremiumNotMasthead = templateType === 'premium' && templateSubType !== 'masthead';
	saveButton = article.querySelector('.save-article-wrapper');

	if (saveButton) {
		const { naturalid: articleId } = saveButton.dataset;
		uri = saveButton.dataset.uri;
		fireGAEvent({ eventAction: 'load', eventLabel: `save-article-in-view-${uri}` });
		getSaveArticle({
			uri,
			articleId,
			element: saveButton,
			isPremiumNotMasthead,
			onToggle: generateForYouPopup,
			isMastheadExcluded,
			isSocialIconsNotExist: isMastheadExcluded && isSocialSharingContainerExist,
			isBrandVoice,
			isDateWrapperExist,
		});
	}
};

saveArticleFunctionality(document);

export default saveArticleFunctionality;
