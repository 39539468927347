import { getArticleSummary } from '../article/shared/getArticleSummary';
import { getHighlights } from '../article/shared/highlights';

/**
 * Hydrates the GenAISummary component after being rendered from the server.
 */
const hydrateGenAISummary = () => {
	const { genAIData } = window.forbes['simple-site'] || {};
	const {
		summary: {
			naturalId = '', summary = '', bullets = [], topics = [],
		} = {},
	} = genAIData || {};
	const genAISummaryContainer = document.getElementById(`genAISummary_${naturalId}`);
	const summaryContentPresent = !!bullets?.length || summary;

	if (genAISummaryContainer && summaryContentPresent) {
		getArticleSummary(summary, bullets, topics).hydrate(genAISummaryContainer);
	}
};

/**
 * Hydrates the Highlights component after being rendered from the server.
 */
const hydrateHighlights = () => {
	const { genAIData } = window.forbes['simple-site'] || {};
	const { highlights: { naturalId = '' } = {} } = genAIData || {};
	const highlightContainer = document.getElementById(`highlights__container_${naturalId}`);

	if (highlightContainer && genAIData) {
		getHighlights(genAIData).hydrate(highlightContainer);
	}
};

/**
 * Function to Hydrate all Article AI Components
 */
const hydrateArticleAIComponents = () => {
	hydrateGenAISummary();
	hydrateHighlights();
};

document.addEventListener('DOMContentLoaded', () => hydrateArticleAIComponents());

export default hydrateArticleAIComponents;
