import { getAssetPriceTicker } from '../profile/react/assetPriceTicker';

/**
 * Hydrates the AssetPriceTicker component after being rendered from the server.
 */
const hydrateAssetPriceTicker = () => {
	const assetPriceTickerContainer = document.getElementById('assetticker__container--fda');
	const { topAssets } = window.forbes['simple-site'];

	if (assetPriceTickerContainer && topAssets?.length) {
		getAssetPriceTicker(topAssets).hydrate(assetPriceTickerContainer);
	}
};

export default hydrateAssetPriceTicker;
