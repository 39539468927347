/* eslint-disable custom-rules/no-duplicate-requires */
const React = require('react');
const { PropTypes } = require('prop-types');

const { default: SubNav } = require('@forbes/fbs-components/dist/cjs/components/SubNav/SubNav');

const { vettedLogo } = require('../../../svg');

const { fireGAEvent } = require('../../shared/trackingUtils');

/**
 * Fires a google analytics event
 * @param {string} label event label
 */
const triggerGAEvent = (label) => {
	fireGAEvent({
		eventCategory: 'Template Area Interaction',
		eventAction: 'click',
		eventLabel: label,
	});
};

/**
 * Returns an object with tracking properties for an event.
 * @param {string} label event label
 *
 * @returns {object} The tracking properties.
 */
const getSubnavTrackingProps = (label) => ({
	track: () => {
		triggerGAEvent(label);
	},
	label,
});

/**
 * Parses subnav items
 * @param {Object[]} topNav list of sub nav items
 *
 * @returns List of parsed subnav items
 */
const parseSubnavItems = (topNav = []) => (
	topNav.map((topNavItem, index) => ({
		name: topNavItem.title,
		href: topNavItem.url,
		trackingProps: getSubnavTrackingProps(`Vetted Nav - Position ${index + 1} - ${topNavItem.title}`),
		...((topNavItem.sections)?.length > 0 && {
			subItems: topNavItem.sections.map((section) => ({
				name: section.sectionTitle,
				href: section.sectionUrl,
				trackingProps: getSubnavTrackingProps(`Vetted Nav - Position ${index + 1} - ${topNavItem.title} - ${section.sectionTitle}`),
			})),
		}),
	})));

/**
 * Vetted Sub Nav component
 * @param {Object[]} subnavItemsData list of sub nav items
 *
 * @returns Sub Nav component with logo
 */
const VettedSubNav = ({ subnavItemsData }) => {
	const formattedSubnavItems = parseSubnavItems(subnavItemsData);

	const handleLogoTracking = () => {
		triggerGAEvent('Vetted Nav - Home Page');
	};

	return (
		<SubNav
			alignment="right"
			className="vetted-subnav-container"
			classNameNav="linkListWrapper"
			data-testid="vetted-subnav"
			items={formattedSubnavItems}
			logo={(
				<a
					aria-label="Forbes Vetted Home Page"
					className="vetted-subnav-homepage"
					href="/vetted/"
					onClick={handleLogoTracking}
				>
					{vettedLogo}
				</a>
			)}
		/>
	);
};

VettedSubNav.propTypes = {
	subnavItemsData: PropTypes.array,
};

module.exports = {
	VettedSubNav,
};
