import React from 'react';
import ReactDOM from 'react-dom';
import { ShareIconWithMenu } from '@forbes/fbs-components';
import getSocialShareMenuData from './socialShareMenuData';

const mainElement = document.querySelector('.social-share-menu');

/**
 * Renders the "socialShareMenu" Component.
 */
const socialShareMenu = () => {
	if (mainElement) {
		const props = getSocialShareMenuData({
			uri: window.forbes['simple-site'].tracking.customPage,
			socialMap: window.forbes['simple-site'].socialMap,
		});
		ReactDOM.render(<ShareIconWithMenu {...props} />, mainElement);
	}
};

socialShareMenu();
let counter = 0;

const addObservers = () => {
	const stickyAd = document.querySelector('fbs-ad[position="mobile"]');
	if (counter > 100) {
		return;
	}

	if (!stickyAd) {
		setTimeout(() => {
			addObservers();
			counter++;
		}, 1000);
	} else {
		const sizeObserver = new ResizeObserver(((entries) => {
			const shareMenuModal = document.querySelector('.share-menu-modal');
			entries.forEach((entry) => {
				if (entry && shareMenuModal) {
					shareMenuModal.style = `bottom: ${entry.contentRect.height + 20}px;`;
				}
			});
		}));

		const menuObserver = new MutationObserver(((mutations) => {
			const shareMenuModal = document.querySelector('.share-menu-modal');
			mutations.forEach((mutation) => {
				if (mutation.addedNodes.length) {
					shareMenuModal.style = `bottom: ${stickyAd.getBoundingClientRect().height + 20}px;`;
				}
			});
		}));

		menuObserver.observe(mainElement, {
			childList: true,
		});
		sizeObserver.observe(stickyAd);
	}
};

window.addEventListener('DOMContentLoaded', () => {
	const isMobile = (window.forbes['simple-site'] || {}).isMobile;
	if (isMobile && mainElement) {
		addObservers();
	}
});
