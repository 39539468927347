const profileNames = {
	COLLEGE: 'collegeprofile',
	COMPANY: 'companyprofile',
	PERSON: 'personprofile',
	TEAM: 'teamprofile',
	NFT: 'nftprofile',
	EXAMPLE: 'exampleprofile',
	DIGITAL_ASSETS: 'digitalassetsprofile',
	DIGITAL_EXCHANGES: 'digitalexchangesprofile',
	NFT_COLLECTIONS: 'nftcollectionprofile',
};

const isEmpty = (obj) => Object.keys(obj).length === 0;

const videoExtractor = (videos) => (
	Array.isArray(videos)
		? videos.find((content) => ((content || {}).video || {}).videoId)
		: null
);

/**
 * creates a string to be used as a node value from an array of strings
 * @param {Array} splitText array of strings to be edited
 * @param {Number} limit the index in negative to where the split text will be edited
 * @returns a string to be used as a node value
 */
const updateNodeValue = (splitText = [], limit) => {
	const truncatedText = splitText.slice(0, splitText.length + limit).join('');
	const lastSpace = splitText[splitText.length + limit] === ' ' ? -1 : truncatedText.lastIndexOf(' ');
	const nodeValue = truncatedText.slice(0, lastSpace !== -1 ? lastSpace : truncatedText.length);

	return nodeValue;
};

/**
 * Determines if Visual Website Optimizer (VWO) is enabled.
 *
 * @param {Object} headers - An object containing the request headers.
 * @param {boolean} isUsDpa - Flag indicating if the request is subject to the US Data Protection Act.
 * @param {boolean} isChina - Flag indicating if the request originates from China.
 * @param {boolean} isEurope - Flag indicating if the request originates from Europe.
 * @returns {boolean} True if VWO is enabled and the request is not from restricted regions, otherwise false.
 */
const getIsVwoEnabled = (headers, isUsDpa, isChina, isEurope) => headers['is-vwo-enabled'] === 'true' && !isUsDpa && !isChina && !isEurope;

module.exports = {
	profileNames,
	isEmpty,
	videoExtractor,
	updateNodeValue,
	getIsVwoEnabled,
};
