const { useReactComponent } = require('../../../../app/shared/componentLibraryService');
const { GenAISummary } = require('../../react-components/GenAISummary/GenAISummary');

/**
 * Gets GenAI Summary component
 * @param {string} summary the summary to be shown.
 * @param {Object[]} bullets the summary in bullets
 * @param {Object[]} topics the suggested content array.
 * @returns Server side ready react component
 */
const getArticleSummary = (summary, bullets, topics) => useReactComponent(GenAISummary, {
	summary,
	bullets,
	topics,
});

module.exports = {
	getArticleSummary,
};
