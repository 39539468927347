const contribFetch = '/simple-data/contrib-permissions';

/**
 * Fetch a contribs editting permissions
 * @param {String} publicationId Id for the publication from the authorGroup
 */
const fetchContribStatus = (publicationId = '') => {
	fetch(contribFetch, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ publicationId }),
	})
		.then((res) => res.json())
		.then((data = false) => {
			if (data) {
				const { streamIndex = 0 } = window.forbes['simple-site'];
				const anchor = document.querySelector(`#article-container-${streamIndex} .edit-story-container`);
				if (anchor) {
					anchor.classList.remove('hide-button');
				}
			}
		})
		.catch((error) => console.error('contributor management error: ', error));
};

export { fetchContribStatus as default };
