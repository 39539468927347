import React from 'react';
import ReactDOM from 'react-dom';

import Notification from './Notification';

const renderOnSiteNotifications = () => {
	const container = document.querySelector('#onsite-notifications');

	if (container) {
		ReactDOM.render(<Notification />, container);
	}
};

renderOnSiteNotifications();
