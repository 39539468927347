/* eslint-disable no-unused-expressions */
import {
	handleFooterAdLabel, updateMiniPublishDates,
} from '../shared/articleUtilities';
import { updateGamzoneUserState } from '../../shared/clientConfigService';
import { insertMedianet, addNewConfig } from '../../shared/adInsertion';
import { storyPackageNavbarInit } from '../shared/storyPackage';
import { updateCarousel } from '../shared/anchorNav';
import { handleAutoplayVideos, runAfterMeterVerified } from '../../shared/paywallUtils';
import { setLiveTemplateMetrics } from '../shared/liveUpdateArticle';
import { setUpPromoBlock } from './topStories';
import { initGrid, getGridState } from '../../shared/grid';
import truncateContribBiosForArticle from '../shared/truncateContribBiosForArticle';
import { addPrintEventListener } from './printService';
import toplineAnimation from '../shared/toplineAnimation';
import fetchContribStatus from './contributorManagement';
import addMobileNavigator from '../shared/mobileShare';

function getFirstAuthorName() {
	const authorNameMeta = document.querySelector('meta[property="article:author"]');
	return authorNameMeta ? authorNameMeta.getAttribute('content') : '';
}

/**
 * Update the zone before inserting the next or first article so the ads that bootstrap have the corresponding zone
 * (@todo: refactor this to make more sense)
 * @param {*} article Article to update ad zone for
 */
function updateAdZoneEntry(article) {
	/* eslint-disable-next-line no-underscore-dangle */
	if ((window.fbsads || {})._config) {
		/* eslint-disable-next-line no-underscore-dangle */
		window.fbsads._config.ad_unit_path = updateGamzoneUserState(`/${article.serverData.adInventory}/fdc.forbes/${article.serverData.adZone}`);
		/* eslint-disable-next-line no-underscore-dangle */
		addNewConfig(article.streamIndex, window.fbsads._config.ad_unit_path);
	}
}

function fetchLoadedArticles(list) {
	const {
		loadedArticles, element, title, pathname, serverData, naturalId, specialSlot,
	} = list;

	loadedArticles.push({
		article: {
			naturalId,
			specialSlot,
			title,
			uri: `https://www.forbes.com${pathname}`,
			authorGroup: {
				primaryAuthor: {
					name: getFirstAuthorName(),
				},
			},
		},
		element,
		historied: true,
		serverData,
	});

	updateAdZoneEntry(loadedArticles[0]);

	return loadedArticles;
}

/**
 * Adds live metrics depending on serverData
 * @param {Object} serverData
 * @param {HTMLElement} element used to add pageviews to DOM
 * @param {Number} naturalId use to get article id
 */
function addMetrics(serverData, element) {
	if (serverData.tracking.templateType === 'live') {
		setLiveTemplateMetrics(element);
	}
}

/**
 * Adds the onLoad article to the loadedArticles array.
 *
 * @param {Object} data used to get first article
 */
function addFirstArticle(data) {
	const {
		currentArticleIndex, mnetBodyBottomAdSize, mnetBodyBottomAdId, pauseAdsObservable, progressiveAdsObservable, streamObservable,
	} = data;

	const {
		'simple-site': serverData = {},
		'simple-site': {
			tracking: {
				login = '', templateType = '', templateSubType = '', naturalID: naturalId = '', slot: specialSlot = '',
			} = {},
		} = {},
	} = window.forbes;

	window.forbes['simple-site'].streamIndex = 0;
	const element = document.getElementById(`article-container-${currentArticleIndex}`);

	handleFooterAdLabel(0);
	const articlesData = {
		element, serverData, naturalId, specialSlot, title: document.title, loadedArticles: data.loadedArticles,
	};

	articlesData.pathname = window.location.pathname;

	const loadedArticles = fetchLoadedArticles(articlesData);

	storyPackageNavbarInit(element, serverData.storyPackageDynamicStyles);
	updateCarousel(element, serverData.anchorNavStyles);
	pauseAdsObservable.notify();
	handleAutoplayVideos({ element, videoSelector: 'fbs-video[autoplay]' });

	addMetrics(serverData, element);
	addMobileNavigator(element);

	// passing in false to getGridState for no ads
	serverData.shouldGetMoreFromBlock ? initGrid(element, getGridState(false)) : '';

	const progressiveAds = Array.from(document.querySelectorAll(`#article-container-${currentArticleIndex} fbs-ad[progressive]`));
	runAfterMeterVerified([progressiveAdsObservable]);
	progressiveAdsObservable.notify(progressiveAds);

	login === 'true' ? fetchContribStatus(serverData.publicationId || '') : '';
	templateType === 'topline' ? toplineAnimation(currentArticleIndex) : '';

	addPrintEventListener(currentArticleIndex);
	streamObservable.notify(serverData);
	serverData.historied = true;
	document.removeEventListener('DOMContentLoaded', addFirstArticle);
	loadedArticles[0].imagesInBody = loadedArticles[0].element.querySelectorAll('.animatable');
	truncateContribBiosForArticle(0, serverData.isPremiumTemplateType);
	updateMiniPublishDates(templateType, document);
	setUpPromoBlock();

	templateSubType.includes('masthead') ? insertMedianet({ position: 'body-medianet-0', size: mnetBodyBottomAdSize, crid: mnetBodyBottomAdId }) : insertMedianet({ position: 'body-medianet-0' });
}

export {
	addFirstArticle,
	updateAdZoneEntry,
};
