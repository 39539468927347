import checkStatus from '../shared/checkStatus';
import { getFvid } from '../shared/tracking';

// eslint-disable-next-line import/no-unresolved, import/extensions
import { template as savedArticles } from '../../../../dist/templates/_savedArticlesUserData';

/**
 * Use user subscriber id or client id if user is not logged in.
 * @param {boolean} isLoggedIn True if user is logged in
 * @returns {Object} the user id to use for api call.
 */
const checkUserId = (isLoggedIn) => ({
	userId: getFvid(),
	defaultData: !!isLoggedIn,
});

/**
 * Fetch for-you homepage API call.
 * @param {string} userId user id to fetch the data.
 * @param {boolean} defaultData determine to fetch default data.
 * @param {string} api the api to fetch data from
 * @param {string} method method used to access api (defaults to GET)
 * @returns {Object} the for-you API results.
 */
// Temporary ignore unused vars as part of SS-9178
// eslint-disable-next-line arrow-body-style
const fetchForYouData = async ({ userId, defaultData }, api, method = 'GET') => { // eslint-disable-line no-unused-vars
	// const api = `https://seal.forbes.com/user?n=${maxArticles}&userId=${userId}&default=${defaultData}`;
	// Temporary add as part of SS-9178
	const options = { method };
	if (method === 'POST') {
		options.headers = { 'Content-Type': 'application/json' };
		// we need to pass empty body here,
		// if we pass no body at all the endpoint will error out
		options.body = JSON.stringify({});
	}

	return fetch(api, options)
		.then((response) => checkStatus(response))
		.then((res) => res.json())
		.then((data) => data);
};

/**
 * Fetch data for "For You" block, for the logged-in user
 * @param {Object} userParams userParams object.
 * @returns {Promise<Array>} Array containing the data to be used inside For You block.
 */
const fetchDataForLoggedInUser = async (userParams) => {
	const { recommendApi } = window.forbes?.['simple-site'] || {};
	const response = await fetchForYouData(userParams, `https://${recommendApi}/cr/${userParams.userId}`, 'POST');

	return response.data || [];
};

/**
 * Fetch data for "For You" block, for the guest user
 * @param {Object} userParams userParams object.
 * @returns {Promise<Array>} Array containing the data to be used inside For You block.
 */
const fetchDataForGuestUser = async (userParams) => {
	const response = await fetchForYouData(userParams, '/forbesapi/stream/premiumcontent.json?limit=6');
	return response.contentList || [];
};

/**
 * Fetch data for "For You" block, using different endpoints for logged-in vs logged-out users
 * @param {boolean} isLoggedIn True if user is logged in
 * @returns {Promise<Array>} Array containing the data to be used inside For You block.
 */
export const fetchForYouDataWrapper = async (isLoggedIn) => {
	const userParams = checkUserId(isLoggedIn);

	try {
		return isLoggedIn
			? await fetchDataForLoggedInUser(userParams)
			: await fetchDataForGuestUser(userParams);
	} catch (e) {
		console.error('Error when fetching data for For You block');
		return [];
	}
};

/**
 * Fetch data for "For You" block, for the logged-in user and send it to the savedArticles template
 * @param {Object} zephrUser the zephr user window object data.
 */
export const fetchDataForSavedArticlesTemplate = async (zephrUser) => {
	const userParams = checkUserId(zephrUser);
	const userSavedArticles = (await fetchDataForLoggedInUser(userParams)).slice(0, 3);

	return savedArticles({ data: userSavedArticles });
};
