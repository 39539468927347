import { createSimpleElement } from '../../../shared/domUtilities';
import { advoiceBrand } from '../../../shared/clientConfigService';
import { getSwimlane } from '../../../shared/adUtilities';

// hardcoded value to easily toggle functionality
const renderAdxFirst = true;

const ntvAdsHolder = [];
const ntvAdsData = {};

/**
 * Determines the Brandvoice monetization logic
 *
 * @param {boolean} isAdvisor
 * @returns {boolean}
 */
const getRenderAdxFirst = (isAdvisor) => {
	const swimlaneUrl = advoiceBrand ? '' : getSwimlane();
	const swimLaneValue = ((window.forbes || {})['simple-site'] || {}).swimLane || swimlaneUrl;
	return renderAdxFirst && !swimLaneValue && !isAdvisor;
};

/**
* Checks if the current article is brandvoice or insights
*/
export const isAdInsightsArticle = () => (['ad', 'insights'].indexOf(((window.forbes['simple-site'] || {}).tracking || {}).blogType || '') >= 0);

/**
* Displays the ntv-ads container
* @param {Number} streamIndex  Current position in the stream
* @param {HTMLElement} ntvAdsElement the vestpocket element that contains the ntv ads
*/
const displayContainer = (streamIndex = 0, ntvAdsElement = '') => {
	const currentArticleNtvWrapper = document.querySelector(`#article-stream-${streamIndex} .ntv-wrapper`);
	currentArticleNtvWrapper.style.display = 'flex';
	ntvAdsElement.setAttribute('displayNtv', true);
};

/**
 * Initializes the adZone and streamIndex values for the current article to be used in rendering NTV Ads
 * after top ad and rec ads are loaded
 * @param {Number} streamIndex Current position in the stream
 * @param {String} adZone The current article's adzone
 */
export const setNTVAdsValues = (streamIndex, adZone) => {
	ntvAdsData.streamIndex = streamIndex;
	ntvAdsData.adZone = adZone;
};

/**
 * Render the correct ad and removes event listeners
 * @param {Object} event listener target used to scale ad.
 * @param  {Array} args collection of elements required for rendering ads.
 */
const isNtvEmpty = (event, ...args) => {
	const [
		i, isAdvisor, ntvAdsElement, ntvAd,
	] = args;
	const isEmpty = !event.detail.size || (event.detail.size[0] === 1 && event.detail.size[1] === 1);
	const ad = event.target;

	if (ad && isEmpty) {
		ad.parentElement.style.display = 'none';

		// we need to check if all ads are invisible in the last iteration, if so, the vest pocket must not be displayed
		if (i === 2) {
			const ntvAds = ntvAdsElement.getElementsByClassName('ntv-ad');

			if (ntvAds[0].style.display === 'none' && ntvAds[1].style.display === 'none') {
				ntvAdsElement.style.display = 'none';
			}
		}
		// if article template is advisor, no ads in vest pocket, so do not display it
		if (isAdvisor) {
			ntvAdsElement.style.display = 'none';
		}
	} else if (!ntvAdsElement.hasAttribute('displayNtv')) {
		displayContainer(ntvAdsData.streamIndex, ntvAdsElement);
	}

	ntvAd.removeEventListener('render', isNtvEmpty);
};

/**
* Create title for ntvAdsElement.
* @param {Object} ntvAdsElement Used to append the title to element.
*/
const createTitle = (ntvAdsElement) => {
	const title = createSimpleElement('p', {
		'aria-hidden': 'true',
		'data-nosnippet': 'true',
	});

	title.innerText = 'PROMOTED';
	ntvAdsElement.appendChild(title);
};

/**
 * Check if ad is vestpocket or insights
 * @param {Object} ntvAdsElement Used to check if ad vestpocket
 * @returns {Boolean} False if vestpocket exists
*/
const checkAdsElement = (ntvAdsElement) => {
	let result = true;

	// If there's no vestpocket or it's already filled - let's get outta here
	if (!ntvAdsElement || ntvAdsElement.children.length) {
		result = false;
	}

	// If it's a brandvoice/insights article, we remove the ntvAdsElement.
	if (result && isAdInsightsArticle()) {
		ntvAdsElement.remove();
	}

	return result;
};

const createNtvWrapper = () => createSimpleElement('div', {
	'aria-hidden': 'true',
	tabindex: '-1',
	class: 'ntv-wrapper',
});

const createNtvAdElement = (index, progressive) => {
	const wrapper = createSimpleElement('div', { class: 'ntv-ad' });
	const ad = createSimpleElement('fbs-ad', {
		position: 'ntv-rail-2',
		'ad-id': `ntv-rail-${index}-${ntvAdsData.streamIndex}`,
		progressive,
		sizes: progressive && index === 1 ? '300x250' : undefined,
	});

	wrapper.appendChild(ad);
	return { wrapper, ad };
};

const handleNtvAdRender = (event, index, isAdvisor, ntvAdsElement, ntvAd) => {
	isNtvEmpty(event, index, isAdvisor, ntvAdsElement, ntvAd);
	// if shouldLoadAdxFirst was set, the initial render will be called from i = 1
	// if creativeTemplateId is set, that means the rendered ad is Brandvoice ad
	// if not, it means the rendered ad is AdX, so we don't load other 2 ads
	// if shouldLoadAdxFirst was not set, that means the ad is not progressive,
	// so we don't need to manually call display():
	// https://developers.google.com/publisher-tag/guides/control-ad-loading
	if (index !== 1) return;
	if (!!event.detail.creativeTemplateId && getRenderAdxFirst(isAdvisor)) {
		ntvAdsHolder[0].display();
		ntvAdsHolder[2].display();
	} else if (getRenderAdxFirst(isAdvisor)) {
		[ntvAdsHolder[0]?.parentElement, ntvAdsHolder[2]?.parentElement].forEach((siblingParent) => {
			if (siblingParent) {
				siblingParent.style.display = 'none';
			}
		});
		// apply appropriate styling for adx that loads in middle ntv-ad slot
		if (ntvAdsHolder[1]?.parentElement) {
			ntvAdsHolder[1].parentElement.style.paddingLeft = '0';
			ntvAdsHolder[1].style.display = 'flex';
			ntvAdsHolder[1].style.alignItems = 'center';
			ntvAdsHolder[1].style.justifyContent = 'center';
			ntvAdsHolder[1].style.width = '100%';
			ntvAdsHolder[1].firstElementChild.style.width = '100%';
			ntvAdsHolder[1].firstElementChild.style.textAlign = 'center';
		}
	}
};

/**
 * Waits for an element matching the provided selector to appear in the DOM.
 *
 * @param {string} selector - The CSS selector to match against.
 * @param {number} timeout - Disconnect observer if element doesn't appear for this long.
 * @returns {Promise<HTMLElement|null>} - A promise that resolves to the found DOM element or null if not found.
 */
const waitForElem = (selector, timeout) => new Promise((resolve, reject) => {
	if (document.querySelector(selector)) {
		resolve(document.querySelector(selector));
	} else {
		const observer = new MutationObserver(() => {
			if (document.querySelector(selector)) {
				resolve(document.querySelector(selector));
				observer.disconnect();
			}
		});

		observer.observe(document.body, {
			childList: true,
			subtree: true,
		});

		setTimeout(() => {
			observer.disconnect();
			reject(new Error(`Element with selector "${selector}" not found within ${timeout}ms.`));
		}, timeout);
	}
});

/**
* Inserts inline-ntv ads on each article
*/
export const renderNTVAds = () => {
	const ntvAdsElement = document.querySelector(`#article-stream-${ntvAdsData.streamIndex} .vestpocket`);

	if (!checkAdsElement(ntvAdsElement)) { return; }

	createTitle(ntvAdsElement);
	const ntvWrapperElement = createNtvWrapper();
	ntvAdsElement.appendChild(ntvWrapperElement);

	const isAdvisor = ntvAdsData.adZone.includes('/advisor');
	const numAdsToShow = isAdvisor ? 1 : 3;

	for (let i = 0; i < numAdsToShow; i++) {
		const { wrapper, ad } = createNtvAdElement(i, getRenderAdxFirst(isAdvisor));
		ntvWrapperElement.appendChild(wrapper);
		ntvAdsHolder[i] = ad;

		ad.addEventListener('render', (event) => handleNtvAdRender(event, i, isAdvisor, ntvAdsElement, ad));
	}

	if (getRenderAdxFirst(isAdvisor)) {
		const secondAd = ntvAdsHolder[1];
		// when internal div of fbs-ad is available, that means we can fire display()
		waitForElem(`#${secondAd.getAttribute('ad-id')}`, 5000).then(() => {
			secondAd.display();
		});
	}
};
