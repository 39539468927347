const { useReactComponent } = require('../../../../app/shared/componentLibraryService');
const { HighlightsWrapper } = require('../../react-components/Highlights/Highlights');

/**
 * Highlights the intended text inside the full article
 * @param {Object} genAIData Article's AI object
 * @returns Server side ready react component
 */
const getHighlights = (genAIData) => useReactComponent(HighlightsWrapper, {
	genAIData,
});

module.exports = {
	getHighlights,
};
