const React = require('react');
const { PropTypes } = require('prop-types');

const { default: CardCrypto } = require('@forbes/fbs-components/dist/cjs/components/CardCrypto/CardCrypto');
const { default: AnimatedCarousel } = require('@forbes/fbs-components/dist/cjs/components/AnimatedCarousel/AnimatedCarousel');
const { default: HeaderBox } = require('@forbes/fbs-components/dist/cjs/components/HeaderBox/HeaderBox');

const { reactComponentsTracking } = require('../../shared/react-tracking');

/**
 * Formats the values of top assets
 * @param {Object[]} topAssets list of top assets
 * @returns Formatted top assets
 */
const formatTopAssets = (data) => {
	const topAssets = [...data, ...data];

	return ((topAssets).map((topAsset = {}) => ({
		...topAsset,
		slug: `https://www.forbes.com/${topAsset.slug}/`,
		image: '',
	})));
};

/**
 * Returns the tracking data
 * @param {string} action the action name
 * @param {string} label the tracking label
 * @returns Formatted top assets
 */
const getTrackingProps = (action, label) => ({
	track: reactComponentsTracking,
	category: 'Forbes Digital Assets',
	action,
	label,
});

const headerData = {
	url: 'https://www.forbes.com/digital-assets/crypto-prices/',
	shortTitle: 'Top 10',
	title: 'Top 10 Crypto',
};

const AssetPriceTicker = ({ topAssets }) => (
	!!topAssets.length && <div className='priceTickerContainer'>
	  <HeaderBox
	  	className="headerBox"
		data={headerData}
		trackingProps={getTrackingProps(
		  'Click',
		  'fda-article-ticker-category-click{Top10Crypto}'
		)}
	  />
	  <AnimatedCarousel
		className="assetPriceTicker"
		pauseOnHover
		trackingProps={getTrackingProps('Pause', 'fda-article-ticker-horizontalscroll-pause')}>
		{formatTopAssets(topAssets).map((assetData, index) => (
		  <CardCrypto
			className="tickerCard"
			data={{...assetData}}
			key={`crypto-card-${index}`}
			noChart
			trackingProps={getTrackingProps(
			  'Click',
			  `fda-article-ticker-{${assetData.name}}-click`
			)}
		  />
		))}
	  </AnimatedCarousel>
	</div>
);

AssetPriceTicker.propTypes = {
	topAssets: PropTypes.array.isRequired,
};
  
module.exports = {
	AssetPriceTicker,
};
