import React from 'react';
import ReactDOM from 'react-dom';
import { SaveArticle } from '@forbes/fbs-components';
import { reactComponentsTracking } from '../../shared/react-tracking';

const getSaveArticle = ({
	uri,
	articleId,
	element,
	isPremiumNotMasthead = false,
	onToggle,
}) => {
	const trackingProps = {
		saveLabel: `save-article-save-click-${uri}`,
		unSaveLabel: `save-article-unsave-click-${uri}`,
		category: 'Template Area Interaction',
		action: 'click',
		track: reactComponentsTracking,
	};

	ReactDOM.render(
		<SaveArticle classNameSaved={isPremiumNotMasthead && 'premium-saved-article'} className={isPremiumNotMasthead && 'premium-save-article'} onToggle={onToggle} articleId={articleId} trackingProps={trackingProps} />,
		element);
};

const moreForYou = document.querySelectorAll('.seo-save-article');

moreForYou.forEach((forYouElement) => {
	const { uri, naturalid: articleId } = forYouElement.dataset;
	getSaveArticle({
		uri, articleId, element: forYouElement,
	});
});

export default getSaveArticle;
