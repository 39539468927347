const { gtmQueue } = require('./gtmQueue');

/**
 * Push tracking event to Google Tag Manager data layer.
 *
 * @param {string} category Category of tracking event.
 * @param {string} action Action of tracking event .
 * @param {string} label Label of tracking event.
 * @param {number} label value of tracking event.
 */
const reactComponentsTracking = (category, action, label, value) => {
	gtmQueue.pushTask(() => {
		window.dataLayer.push({
			event: 'analyticsEvent',
			'event category': category,
			'event action': action,
			'event label': label,
			...(value && { 'event value': value }),
		});
	});
};

module.exports = {
	reactComponentsTracking,
};
