import { recalculateAdRail } from './adRailService';
import throttle from '../../../shared/throttle';

let currentScrollPosition = 0;

/**
 * Checks if an ad is in the correct article
 * @param {HTMLElement} ad The ad looking to be recalled
 * @returns {Boolean} True if it is go time
 */
const isAdInsideCurrentArticle = (ad = {}) => {
	const { streamIndex = 0 } = window.forbes['simple-site'];
	const adId = (ad.getAttribute('ad-id') || '').split('-')[1];

	return streamIndex === parseInt(adId || 0, 10);
};

/**
 * Reverse lazy loads destroyed ads on an article page.
 */
const reverseLazyLoadAds = () => {
	const newScrollPosition = window.pageYOffset;

	// The user is not scrolling up the page, we should bounce.
	if (currentScrollPosition < newScrollPosition) {
		currentScrollPosition = newScrollPosition;
		return;
	}

	const destroyedAds = Array.from(document.querySelectorAll('fbs-ad[destroyed="true"]'));

	if (destroyedAds.length) {
		destroyedAds.forEach((ad) => {
			// The displayDistance is 25% of the clientHeight pasted the top of the window,
			// which would be a negative value.
			const displayDistance = (ad.clientHeight * -0.25);
			if (ad.getBoundingClientRect().bottom >= displayDistance && isAdInsideCurrentArticle(ad)) {
				ad.setAttribute('destroyed', 'false');
				ad.removeAttribute('batched');
				ad.removeAttribute('progressive');
				ad.recallAd();
			}

			// If we added a min-height to a premium inbody ad, we remove the style element.
			if (ad.style.minHeight) {
				ad.removeAttribute('style');
			}
		});

		currentScrollPosition = newScrollPosition;
	} else {
		/* eslint-disable-next-line no-use-before-define */
		window.removeEventListener('scroll', reverseLazyLoadEvent);
	}
};

/**
 * Scroll event for the lazy loading ads
 */
export const reverseLazyLoadEvent = throttle(() => reverseLazyLoadAds(), 300);

/**
 * Destroys adds on a given article
 * @param {Number} streamIndex The index of the article that should be destroyed.
 */
export const destroyAds = (streamIndex = 0) => {
	const articleContainer = document.querySelector(`#article-container-${streamIndex}.ads-loaded`);
	if (articleContainer) {
		const isPremium = articleContainer.querySelector('.main-content--body.premium');
		const pageAds = articleContainer.querySelectorAll('fbs-ad');
		Array.from(pageAds).forEach((ad) => {
			if (['ntv-rail-2', 'hero', 'artbottom'].indexOf(ad.position) > -1) {
				return;
			}

			if (ad.position === 'inread') {
				ad.classList.remove('inread-active');
			} else {
				ad.setAttribute('destroyed', 'true');

				const dfpChild = ad.firstElementChild;
				if (dfpChild) {
					// We add the value needed to recall an id in the ads package.
					ad.setAttribute('data-ga-id', dfpChild.id);
				}
			}

			// If it's a premium article or inline-topx template is enabled, we need to set an inline min-height to keep
			// the page from jumping.
			const isAdStandardTopx = ad.hasAttribute('data-double-progressive');
			if (isPremium || isAdStandardTopx) {
				ad.style.minHeight = `${ad.clientHeight}px`;
			}

			ad.destroyAd();
		});

		const adRailContainer = articleContainer.querySelector('.ad-rail');
		if (adRailContainer) {
			recalculateAdRail(adRailContainer);
			adRailContainer.classList.add('adrail-destroyed');
		}
	}

	// 2 second delay to ensure the last ad doesn't pop back in right away.
	setTimeout(() => {
		window.addEventListener('scroll', reverseLazyLoadEvent);
	}, 2000);
};
