const React = require('react');

module.exports = {
	fdaLogo:
	<svg xmlns="http://www.w3.org/2000/svg" width="239" height="16" viewBox="0 0 239 16" fill="none">
		<path d="M0 0H9.05509C16.8465 0 20.8 2.77523 20.8 7.80322V7.95929C20.8 13.0212 16.7996 16 9.05509 16H0V0ZM8.8168 12.2137C12.7883 12.2137 14.4636 10.9177 14.4636 8.05089V7.87108C14.4636 4.89228 12.7739 3.75233 8.79153 3.75233H6.10172V12.2409L8.8168 12.2137Z" fill="#1A1A1A" />
		<path d="M22.4004 0H28.8004V16H22.4004V0Z" fill="#1A1A1A" />
		<path d="M30.4004 8.07708V7.9032C30.4004 3.18556 35.3182 0 41.9128 0C47.1766 0 51.7978 1.64035 52.2779 5.69529H46.3611C46.0363 4.43878 44.497 3.72687 41.9587 3.72687C38.6508 3.72687 36.5997 5.30817 36.5997 7.99178V8.16237C36.5997 10.5442 38.0895 12.4076 42.2129 12.4076C45.3373 12.4076 46.6858 11.5645 46.9683 10.3932H42.5624V7.01741H52.8004V9.07441C52.8004 13.2081 49.21 16 41.9199 16C34.4567 16.0131 30.4004 12.7324 30.4004 8.07708Z" fill="#1A1A1A" />
		<path d="M54.4004 0H60.8004V16H54.4004V0Z" fill="#1A1A1A" />
		<path d="M68.198 3.80178H62.4004V0H80.0004V3.80178H74.1572V16H68.1839L68.198 3.80178Z" fill="#1A1A1A" />
		<path d="M85.2095 0H92.4579L99.2004 16H93.2466L92.1949 13.2876H84.7436L83.6686 16H78.4004L85.2095 0ZM86.1313 9.83259H90.8338L88.5042 3.88218L86.1313 9.83259Z" fill="#1A1A1A" />
		<path d="M102.4 0H108.3V12.1982H116.8V16H102.4V0Z" fill="#1A1A1A" />
		<path d="M128.409 0H135.654L142.4 16H136.446L135.391 13.2876H127.926L126.848 16H121.6L128.409 0ZM129.331 9.83259H134.03L131.7 3.88218L129.331 9.83259Z" fill="#1A1A1A" />
		<path d="M144 10.8254H149.914C150.126 11.8116 151.055 12.5842 153.874 12.5842C156.033 12.5842 157.225 12.1042 157.225 11.2396C157.225 10.375 156.145 10.1088 153.164 9.87206C146.89 9.39539 144.612 8.07057 144.612 4.96726C144.612 2.03819 147.852 0 153.424 0C158.913 0 162.063 1.47604 162.498 4.85878H156.739C156.48 3.90543 155.385 3.40575 153.463 3.40575C151.465 3.40575 150.494 3.81996 150.494 4.57935C150.494 5.33874 151.278 5.59844 154.363 5.83513C159.996 6.22634 163.2 7.24543 163.2 10.7399C163.2 13.863 159.996 15.9998 153.938 15.9998C147.585 16.0228 144.202 14.2739 144 10.8254Z" fill="#1A1A1A" />
		<path d="M163.2 10.8254H169.127C169.34 11.8116 170.269 12.5842 173.07 12.5842C175.231 12.5842 176.423 12.1042 176.423 11.2396C176.423 10.375 175.342 10.1088 172.357 9.87206C166.085 9.39539 163.805 8.07057 163.805 4.96726C163.805 2.03819 167.046 0 172.62 0C178.112 0 181.262 1.47604 181.698 4.85878H175.937C175.677 3.90543 174.583 3.40575 172.66 3.40575C170.661 3.40575 169.689 3.81996 169.689 4.57935C169.689 5.33874 170.474 5.59844 173.56 5.83513C179.195 6.22634 182.4 7.24543 182.4 10.7399C182.4 13.863 179.195 15.9998 173.135 15.9998C166.787 16.0228 163.413 14.2739 163.2 10.8254Z" fill="#1A1A1A" />
		<path d="M184 0H199.533V3.69231H189.636V6.13011H198.137V9.71053H189.636V12.3077H200V16H184V0Z" fill="#1A1A1A" />
		<path d="M207.394 3.80178H201.6V0H219.2V3.80178H213.356V16H207.383L207.394 3.80178Z" fill="#1A1A1A" />
		<path d="M219.2 10.8254H225.122C225.335 11.8116 226.261 12.5842 229.067 12.5842C231.228 12.5842 232.42 12.1042 232.42 11.2396C232.42 10.375 231.34 10.1088 228.354 9.87206C222.078 9.39539 219.795 8.07057 219.795 4.96726C219.795 2.03819 223.037 0 228.616 0C234.106 0 237.262 1.47604 237.698 4.85878H231.934C231.675 3.90543 230.58 3.40575 228.653 3.40575C226.657 3.40575 225.684 3.81996 225.684 4.57935C225.684 5.33874 226.466 5.59844 229.557 5.83513C235.191 6.22634 238.4 7.24543 238.4 10.7399C238.4 13.863 235.191 15.9998 229.128 15.9998C222.792 16.0228 219.409 14.2739 219.2 10.8254Z" fill="#1A1A1A" />
	</svg>,
	vettedLogo:
	<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 318.88 78.38">
		<path className="cls-1" d="M30.18,77.24L0,4.11h16.17l20.7,50.78L57.26,4.11h16.48l-30.28,73.13h-13.29.01Z" />
		<path className="cls-1" d="M95.58,78.38c-7.83,0-14.33-2.47-19.52-7.42-5.19-4.94-7.78-11.43-7.78-19.47s2.58-14.09,7.73-19.21c5.15-5.11,11.6-7.67,19.36-7.67,7.14,0,13.15,2.21,18.03,6.64,4.87,4.43,7.31,10.66,7.31,18.69,0,2.27-.07,3.88-.21,4.84h-38.52c.41,3.37,1.91,5.99,4.48,7.88s5.65,2.83,9.22,2.83c6.04,0,10.47-2.16,13.29-6.49l10.51,7.83c-5.29,7.69-13.25,11.54-23.9,11.54h0ZM82.5,45.21h24.1c-.62-2.95-2.01-5.18-4.17-6.69-2.16-1.51-4.65-2.27-7.47-2.27s-5.53.76-7.93,2.27c-2.4,1.51-3.91,3.74-4.53,6.69Z" />
		<path className="cls-1" d="M154.09,77.86c-5.77,0-10.49-1.6-14.16-4.79-3.67-3.19-5.51-7.81-5.51-13.85v-21.11h-10.4v-12.36h10.4v-14.32h14.11v14.32h11.54v12.36h-11.54v18.33c0,3.09.7,5.3,2.11,6.64s3.66,2.01,6.75,2.01c2.54,0,4.43-.27,5.66-.82v12.46c-2.47.75-5.46,1.13-8.96,1.13ZM193.12,77.86c-5.7,0-10.39-1.6-14.06-4.79-3.67-3.19-5.51-7.81-5.51-13.85v-21.11h-8.55v-12.36h8.55v-14.32h14.11v14.32h14.52v12.36h-14.52v18.33c0,3.16.67,5.39,2.01,6.69,1.34,1.31,3.55,1.96,6.64,1.96,2.68,0,4.63-.27,5.87-.82v12.46c-2.47.75-5.49,1.13-9.06,1.13h0Z" />
		<path className="cls-1" d="M234.53,78.38c-7.83,0-14.33-2.47-19.52-7.42-5.19-4.94-7.78-11.43-7.78-19.47s2.58-14.09,7.72-19.21c5.15-5.11,11.6-7.67,19.36-7.67,7.14,0,13.15,2.21,18.03,6.64,4.88,4.43,7.31,10.66,7.31,18.69,0,2.27-.07,3.88-.21,4.84h-38.52c.41,3.37,1.91,5.99,4.48,7.88,2.58,1.89,5.65,2.83,9.22,2.83,6.04,0,10.47-2.16,13.29-6.49l10.51,7.83c-5.29,7.69-13.25,11.54-23.9,11.54h.01ZM221.45,45.21h24.1c-.62-2.95-2.01-5.18-4.17-6.69-2.16-1.51-4.65-2.27-7.47-2.27s-5.53.76-7.93,2.27c-2.4,1.51-3.91,3.74-4.53,6.69h0Z" />
		<path className="cls-1" d="M289.94,78.38c-6.8,0-12.53-2.64-17.2-7.93s-7-11.6-7-18.95,2.33-13.66,7-18.95c4.67-5.29,10.4-7.93,17.2-7.93,3.3,0,6.25.69,8.86,2.06,2.61,1.38,4.6,2.99,5.97,4.84V0h14.11v77.25h-14.11v-5.77c-1.37,1.85-3.37,3.47-5.97,4.84-2.61,1.37-5.56,2.06-8.86,2.06ZM283.5,61.59c2.37,2.61,5.51,3.91,9.42,3.91s7.05-1.3,9.42-3.91c2.37-2.61,3.55-5.97,3.55-10.09s-1.18-7.48-3.55-10.09-5.51-3.91-9.42-3.91-7.06,1.3-9.42,3.91c-2.37,2.61-3.55,5.97-3.55,10.09s1.18,7.49,3.55,10.09Z" />
	</svg>,

};
