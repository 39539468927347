import { useEffect, useState } from 'react';

import { showNotifications, isBelowMedium } from './notificationsUtils';

const usePositionNotifications = () => {
	const [topOffset, setTopOffset] = useState(0);

	useEffect(() => {
		if (!isBelowMedium() || !showNotifications()) return undefined;

		const heroAdSlideUpHandler = () => {
			setTopOffset(0);
		};

		const heroAdHeightHandler = (e) => {
			setTopOffset(e.detail.adHeight);
		};

		document.addEventListener('ha-ribbon-slide-up', heroAdSlideUpHandler);
		document.addEventListener('ha-ribbon-slide-down', heroAdHeightHandler);
		document.addEventListener('ha-ribbon-init', heroAdHeightHandler);

		return () => {
			document.removeEventListener('ha-ribbon-slide-up', heroAdSlideUpHandler);
			document.removeEventListener('ha-ribbon-slide-down', heroAdHeightHandler);
			document.removeEventListener('ha-ribbon-init', heroAdHeightHandler);
		};
	}, []);

	return topOffset;
};

export default usePositionNotifications;
