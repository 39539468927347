import React from 'react';
import { CardArticle, CardStream } from '@forbes/fbs-components';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { fireGAEvent } from '../../shared/trackingUtils';

const FourCardArticle = ({
	isReversed, data, hideImage, isSmallDevice = false,
}) => {
	const triggerGAEvent = (label, value) => {
		fireGAEvent({
			eventCategory: 'Template Area Interaction',
			eventAction: 'click',
			eventLabel: label,
			eventValue: value,
		});
	};

	const largeCard = data[0];
	const smallCards = data.slice(1);

	return (
		<div className={cx('four-card-article', { 'is-reversed': !!isReversed })}>
			<CardArticle
				inViewTrackingProps={largeCard.index === 0 ? {
					category: 'Template Area Interaction',
					action: 'in-view',
					label: 'xl-recirc',
				} : undefined}
				inView={false}
				image={largeCard.image}
				href={largeCard.uri}
				alt={largeCard.alt}
				size={largeCard.size}
				title={largeCard.title}
				isPremium={largeCard.isPremium}
				timestamp={isSmallDevice ? new Date(largeCard.published_date).getTime() : null}
				className="card-article"
				classNameByLines="custom-stream"
				onClick={() => triggerGAEvent(`More From Forbes - XL Recirc - Story ${largeCard.index + 1}`, largeCard.index + 1)}
				authors={[
					{
						link: largeCard.authors[0]?.link,
						name: largeCard.authors[0]?.name,
						type: largeCard.authors[0]?.type,
					},
				]}
			/>
			{!isSmallDevice ? (
				<div className="card-stream-container">
					{smallCards.map(({
						title, uri, authors, published_date: timestamp, index,
					}) => (
						<CardStream
							className="card-stream"
							key={`${title}-${index}`}
							articleUrl={uri}
							title={title}
							timestamp={new Date(timestamp).getTime()}
							target="_self"
							description=""
							onClick={() => triggerGAEvent(`More From Forbes - XL Recirc - Story ${index + 1}`, index + 1)}
							byLineProps={{
								author: [
									{
										link: authors[0]?.link,
										name: authors[0]?.name,
										type: authors[0]?.type,
									},
								],
								displayBy: true,
								size: 'small',
								className: 'custom-stream',
							}}
						/>
					))}
				</div>
			) : (
				<div className="card-article-container">
					{smallCards.map(({
						title, uri, authors, size, alt, index, isPremium, image, published_date: timestamp,
					}) => (
						<CardArticle
							key={`${title}-${index}`}
							image={image}
							showImage={!hideImage}
							href={uri}
							alt={alt}
							size={size}
							title={title}
							isPremium={isPremium}
							timestamp={new Date(timestamp).getTime()}
							className="card-article"
							classNameByLines="custom-stream"
							onClick={() => triggerGAEvent(`More From Forbes - XL Recirc - Story ${index + 1}`, index + 1)}
							authors={[
								{
									link: authors[0]?.link,
									name: authors[0]?.name,
									type: authors[0]?.type,
								},
							]}
						/>
					))}
				</div>
			)}
		</div>
	);
};

FourCardArticle.propTypes = {
	isReversed: PropTypes.bool,
	data: PropTypes.array.isRequired,
	hideImage: PropTypes.bool,
	isSmallDevice: PropTypes.bool,
};

export default FourCardArticle;
