import { checkConsentCookieLoaded, userGaveFullConsent, checkJurisdiction } from './ketchUtils';

const shouldShowOpenWeb = (isChina, isEurope) => {
	const checkRegion = isChina || isEurope;
	const checkRegionAndJurisdiction = checkRegion || checkJurisdiction();

	return (!checkConsentCookieLoaded() && !checkRegion)
	|| !checkRegionAndJurisdiction
	|| (checkRegionAndJurisdiction && userGaveFullConsent());
};

export default shouldShowOpenWeb;
