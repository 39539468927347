import { addSlideChangedListener } from '../../shared/carouselUtilities';
import { streamIndexObservable } from '../desktop/streamService';

/**
 * @param {Number} streamIndex The article index in the stream.
 */
function initCarousel(streamIndex) {
	const currentArticle = document.getElementById(`article-container-${streamIndex || 0}`);
	const vettedCouponCarouselContainer = currentArticle.getElementsByClassName('vetted-coupons__carousel-container')[0];
	const vettedCouponCarousel = currentArticle.getElementsByClassName('vetted-coupon-carousel')[0];
	if (vettedCouponCarousel) {
		const vettedCouponCarouselDots = [...vettedCouponCarouselContainer.querySelectorAll('.vetted-coupons--dots button')];
		const vettedCouponCarouselDotsTabs = [...vettedCouponCarouselContainer.querySelectorAll('fbs-slider__slide')];
		const leftArrow = vettedCouponCarousel.getElementsByClassName('fbs-slider__control-left')[0];
		const rightArrow = vettedCouponCarousel.getElementsByClassName('fbs-slider__control-right')[0];

		vettedCouponCarousel.addEventListener('slideChangeStart', () => {
			leftArrow?.classList.remove('fbs-slider__foucs');
			rightArrow?.classList.remove('fbs-slider__foucs');

			leftArrow.addEventListener('mouseover', () => {
				leftArrow?.classList.add('hover-state');
			});
		});

		leftArrow.addEventListener('mouseout', () => {
			leftArrow?.classList.remove('hover-state');
		});

		rightArrow.addEventListener('mouseover', () => {
			rightArrow?.classList.add('hover-state');
		});

		rightArrow.addEventListener('mouseout', () => {
			rightArrow?.classList.remove('hover-state');
		});

		addSlideChangedListener(vettedCouponCarousel, vettedCouponCarouselDots, vettedCouponCarouselDotsTabs);
	}
}

document.addEventListener('DOMContentLoaded', () => {
	initCarousel(0);
});

streamIndexObservable.subscribe(initCarousel);
