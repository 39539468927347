import { streamIndexObservable } from './streamService';
import { fireGAEvent } from '../../shared/tracking';

/**
 * Used to fire tracking event
 * @param {string} eventLabel The ga tracking event label.
 */
const fireGAEventTracking = (eventLabel) => {
	const GAData = {
		eventCategory: 'Template Area Interaction',
		eventAction: 'click',
		eventLabel,
	};

	fireGAEvent(GAData);
};

/**
 * get tracking label for read more/less button
 * @param {boolean} isExpand ture if the summary is expanded.
 */
const getTrackingLabel = (isExpand) => (isExpand ? 'Vetted UpdateSummary - Expand' : 'Vetted UpdateSummary - Collapse');

/**
 * add Tracking For Links click
 * @param {Element} findsUpdateSummaryModule The finds update summary module.
 */
function addTrackingForLinks(findsUpdateSummaryModule) {
	const summaryText = findsUpdateSummaryModule.querySelector('.summary-content-item .summary-text');
	const linkElements = summaryText.querySelector('a');
	if (linkElements) {
		const trackingLabel = linkElements.getAttribute('data-ga-track');
		fireGAEventTracking(trackingLabel);
	}
}

/**
 * readMoreBtn click event listener
 * @param {Element} findsUpdateSummaryModule The finds update summary module.
 * @param {Element} readMoreBtn The read more button.
 * @param {Element} ellipsis The ellipsis element.
 */
function clickEventListener(findsUpdateSummaryModule, readMoreBtn, ellipsis = null) {
	findsUpdateSummaryModule.classList.toggle('active');
	if (readMoreBtn.innerText === 'See More') {
		readMoreBtn.innerText = 'See Less';
		readMoreBtn.setAttribute('data-ga-track', getTrackingLabel(false));
		if (ellipsis) {
			ellipsis.style.display = 'none';
		}
		findsUpdateSummaryModule.setAttribute('aria-expanded', 'true');
	} else {
		readMoreBtn.innerText = 'See More';
		readMoreBtn.setAttribute('data-ga-track', getTrackingLabel(true));
		if (ellipsis) {
			ellipsis.style.display = 'block';
		}
		findsUpdateSummaryModule.setAttribute('aria-expanded', 'false');
	}
}

/**
 * initialize summary toggle script
 * @param {number} streamIndex The article stream index.
 */
function initSummaryToggle(streamIndex) {
	const currentArticle = document.getElementById(`article-container-${streamIndex || 0}`);
	const findsUpdateSummaryModule = currentArticle.querySelector('.update-summary-container');
	if (findsUpdateSummaryModule) {
		const summaryContentItems = findsUpdateSummaryModule.querySelectorAll('.summary-content-item');
		const seeMoreContent = findsUpdateSummaryModule.querySelector('.see-more-summary');
		const readMoreBtn = findsUpdateSummaryModule.querySelector('.summary-btn');
		const toggleContent = findsUpdateSummaryModule.querySelector('.toggle-summary-content');
		addTrackingForLinks(findsUpdateSummaryModule);
		if (summaryContentItems.length > 1) {
			// toggle summary content if there are multiple summary items
			findsUpdateSummaryModule.setAttribute('aria-expanded', 'false');
			if (toggleContent) {
				seeMoreContent.addEventListener('click', () => {
					clickEventListener(findsUpdateSummaryModule, readMoreBtn);
				});
			}
		} else {
			// hide read more button if there is only one summary item
			seeMoreContent.style.display = 'none';
		}
	}
}

document.addEventListener('DOMContentLoaded', () => {
	initSummaryToggle(0);
});

streamIndexObservable.subscribe(initSummaryToggle);
