import React, { useCallback, useEffect, useState } from 'react';
import { checkIsBrandVoiceContent } from '../../article/shared/articleUtilities';
import {
	checkIsSameDestinationUrl, isNotificationsDismissed, parseNotificationContent, waitForFuseObject,
} from './notificationsUtils';

const FETCH_NOTIFICATIONS_DELAY = 10000;

const withNotificationData = (WrappedComponent) => () => {
	const [notification, setNotification] = useState(null);

	const fetchNotifications = useCallback(async () => {
		try {
			const response = await fetch('https://bacon.forbes.com/bacon-forbes-prd/onsite-notifications/payload.json');
			const results = await response.json();
			const content = parseNotificationContent(results);

			if (!checkIsSameDestinationUrl(content?.url)) {
				setNotification(content);
			}
		} catch (error) {
			console.error('Failed to fetch notifications:', error);
		}
	}, []);

	useEffect(() => {
		if (checkIsBrandVoiceContent() || isNotificationsDismissed()) return;

		waitForFuseObject(FETCH_NOTIFICATIONS_DELAY).then(fetchNotifications);
	}, [fetchNotifications]);

	if (!notification) return null;

	return (
		<WrappedComponent notification={notification} />
	);
};

export default withNotificationData;
