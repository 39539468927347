const { useReactComponent } = require('../app/shared/componentLibraryService');
const { GlobalHeaderWrapper } = require('../assets/js/react-components/GlobalHeader/GlobalHeader');

const getGlobalHeaderComponent = ({
	meta,
	isUs,
	isFda,
	isNewsletter,
	globalHeaderData,
	isProd,
} = {}) => useReactComponent(GlobalHeaderWrapper, {
	meta,
	isUs,
	isFda,
	isNewsletter,
	globalHeaderData,
	isProd,
});

module.exports = {
	getGlobalHeaderComponent,
};
